import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { YellowButton } from '../../components/Global/Button'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { InlineLink } from '../../components/Global/Link'
import SEO from '../../components/Global/SEO'
import { CarrickARedeRopeBridge, GiantsCauseway, GuinnessStorehouse, RingOfKerry, DublinZoo } from '../../components/Tours/Locations'
import CliffsOfMoher from '../../components/Tours/Locations/CliffsOfMoher'
import { ContentWrapper, SVGPattern, InfoWithImageRight, InfoWithImageLeft } from '../../components/Global/Content'
import SectionTitle from '../../components/Global/SectionTitle'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'

const BespokeTour = ({ location }) => {
  const {
    seoImg,
    bushmills,
    guinness,
    kerry
  } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "guinness-gate.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      bushmills: file(relativePath: { eq: "bushmills.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kerry: file(relativePath: { eq: "ring-of-kerry.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      guinness: file(relativePath: { eq: "guinness-gate.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Bespoke Tour | Dublin Zoo, Guinness Storehouse, Ring of Kerry"
        description="The Chauffeur Me team is here at your disposal, to work with you on bespoke requirements. Our bespoke tour-writing services are free of charge and we are happy to research anything one-off or for a specialist theme."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={bushmills.childImageSharp.fluid}
        imageAlt="Bushmills Distillery"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Private Tour
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Bespoke
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Tour</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          The Chauffeur Me team is here at your disposal, to work with you on bespoke requirements. Our bespoke tour-writing services are free of charge and we are happy to research anything one-off or for a specialist theme.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>        
      </LeftWithImage>

      <ContentWrapper>

        <SectionTitle 
          title="Tour Anywhere in Ireland"
          text={<>Tour in comfort with cost effective sightseeing of Ireland as we take you where you want to go in one of Chauffeur Me's <InlineLink to="/our-fleet">luxury fleet</InlineLink> of vehicles.</>}
        />

        <InfoWithImageRight
          image={kerry.childImageSharp.fluid}
          imageAlt="Fantasic views throughout the drive around the Ring of Kerry."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Chauffeured Tours for All
          </h4>
          <p className="mt-3 text-lg leading-7 font-semibold text-gray-500">
            Like a tour you see but what something extra? Or perhaps you want the best of two tours. No problem.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Tweak any of the tour itineraries we have on offer, such as our popular <InlineLink to="/tours/giants-causeway-tour">Causeway Coast Tour</InlineLink>, <InlineLink to="/tours/game-of-thrones-tour">Game of Thrones Tour</InlineLink> or <InlineLink to="/tours/belfast-political-tour">Belfast Political Tour</InlineLink>, to suit your specififcations then let us know your departure and return details and we'll do the rest.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Our bespoke tours also provide flexibility throughout the day, allowing you to spend more (or less) time at certain locations, and we'll keep you advised to ensure you get home on time as scheduled.
          </p>
        </InfoWithImageRight>

        <SVGPattern />

        <InfoWithImageLeft
          image={guinness.childImageSharp.fluid}
          imageAlt="The famous gate at the Guinness Storehouse."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            See What You Want to See
          </h4>
          <p className="mt-3 text-lg font-semibold leading-7 text-gray-500">
            Got places you want to see? Let us create a bespoke tour to suit you.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Whether your tour requirement is a simple half day in Belfast, or a 3 day involved tour of Ireland, we are experienced and able to make it happen!
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Visit anywhere in Ireland from the Giant's Causeway to the Cliffs of Moher, Guinness Storehouse to the Ring of Kerry – Chauffeur Me can take you where you want to go.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Enjoy it all in the comfort of our luxury fleet of vehicles as you revel in the knowledge of our friendly and experienced drivers who are there for you every step of the way.
          </p>
        </InfoWithImageLeft>
      </ContentWrapper>

      <OffsetGridWrapper title="Bespoke Tour Location Ideas">
        <GuinnessStorehouse />
        <RingOfKerry />
        <CliffsOfMoher />
        <GiantsCauseway />
        <DublinZoo />
        <CarrickARedeRopeBridge />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default BespokeTour