import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const CliffsOfMoher = () => {
  const { moher } = useStaticQuery(graphql`
    query {
      moher: file(relativePath: { eq: "cliffs-of-moher.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Cliffs of Moher"
      description="Towering heights and crashing waves, ageless legends and endless flurrying birds: the Cliffs of Moher are a natural masterpiece."
      sources={moher.childImageSharp.fluid}
      imageAlt="Chauffeur Me Tour Location - Cliffs of Moher"
    />
  )
}

export default CliffsOfMoher